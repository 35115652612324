.ant-input-group > .ant-input:first-child, .ant-input-group-addon:first-child {
    border-top-right-radius: 0;
    border-bottom-right-radius: 0;
    padding-left: 28px;
}

.cross-icon {
    z-index: 1000;
    position: relative;
    top: 27px;
    right: 6px;
}

.test:hover, .ant-dropdown-menu-submenu-title:hover {
    background-color: white !important;
}


.login-form {
  width: 100%;
  max-width: 380px;
  padding: 50px 0;
}
.login-form-forgot {
  float: right;
}
.login-form-button {
  width: 100%;
}

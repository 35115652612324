.ant-layout {
  /* background: #ffffff !important; /* should be tailored to the theme */
}

.ant-layout-header {
  /* background: #FFFFFF !important; /* should be tailored to the theme */
}

.mainFooter {
  text-align: center;
}

.whiteFont {
  color: #FFFFFF;
}

a.whiteLink:link, a.whiteLink:visited, a.whiteLink:hover, a.whiteLink:active {
  color: #FFFFFF;
}

a.blackLink:link, a.blackLink:visited, a.blackLink:hover, a.blackLink:active {
  color: #000000;
}

.italic.ant-input:placeholder-shown::placeholder {
  font-style: italic;
}
.normal.ant-input:placeholder-shown::placeholder {
  font-style: normal;
}
.oblique.ant-input:placeholder-shown::placeholder {
  font-style: oblique;
}

.textCenter {
  text-align: center;
}

/* Connect component */
.pageTitle {
  font-size: 28px;
  font-weight: 600;
}

#mainContentElement {
  margin: 24px 16px;
  padding: 24px;
}

.portalPageAlignment {
  align-items: center !important;
}

#selectReturnMethodDropdown {
  width: 500px;
}

@media (min-width: 320px) and (max-width: 480px) {
  #mainContentElement {
    margin: 24px 6px;
    /* padding: 0px; */
  }

  #selectReturnMethodDropdown {
    width: 300px;
  }
}

.NotExpandible .ant-table-row-expand-icon-cell .ant-table-row-expand-icon {
  visibility: hidden;
}

.cross-icon-portal {
  z-index: 2;
  position: relative;
  left: 22px;
}

.productsDrawer .ant-list-item-meta-content{
  width: 100%;
  height: 100%;
  margin-top: 35px;
}

.priceContainer {
  display: flex;
  flex-direction: column;
  text-align: center;
}

.negativeBalanceText {
  color: #f30714;
  margin-top: 5px;
  font-size: small;
}

.positiveBalanceText {
  color: #52c41a;
  margin-top: 5px;
  font-size: small;
}

@media only screen and (max-width: 500px) {
  .intercom-launcher {
    display: none !important;
  }
}
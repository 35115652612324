.ant-select-dropdown-menu-item.ant-select-dropdown-menu-item {
  position: relative;
  display: block;
  padding: 5px 12px;
  overflow: hidden;
  color: #000000;
  font-weight: normal;
  font-size: 14px;
  line-height: 22px;
  overflow-wrap: break-word;
  white-space: normal;
  text-overflow: ellipsis;
  cursor: pointer;
  -webkit-transition: background 0.3s ease;
  transition: background 0.3s ease;
}

.inscentivesBadge .ant-badge{
  display: flex;
  justify-content: space-between;
  align-items: center;
  line-height: 20px;
}

.inscentivesBadge .ant-badge-count{
  position: static; 
  transform: none;
  width: 40%;
  font-size: 10px;
}

.returnProducts .ant-select-selection-selected-value .ant-badge-count {
  display: none;
}

.returnProducts .ant-form-item-control-wrapper{
  width: 85%;
}

.returnProducts .ant-form-item-label {
  display: flex;
  justify-content: space-between;
  width: 40%;
}

/* Media query for mobile devices */
@media only screen and (max-width: 992px) {
  .returnProducts .ant-form-item-control-wrapper {
    width: 100%;
  }
  .inscentivesBadge .ant-badge-count{
    width: 45%;
  }
}

/* Color variables */
:root {--rrPrimary: #67C2C2; }
.website-button {
    background-color: #67c2c2 !important;
    border-color: #67c2c2 !important;
    color: #fff !important;
}

.website-button:hover {
    background-color: #37bbbb !important;
    border-color: #37bbbb !important;
}

.error-title{
    font-size: 100px;
    line-height: 80px;
}


@media only screen and (max-width: 992px) {
    .error-404-logo-desktop{
        display: none;
    }
}

@media only screen and (min-width: 768px) {
    .error-404-logo-mobile{
        display: none;
    }
} 